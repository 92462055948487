<template>
  <div>
    <v-form>
      <p>
        Sucrose is a carbohydrate; a molecule made up of carbon, hydrogen, and oxygen atoms. The
        approximate elemental make-up of most carbohydrates is 1 carbon for every 2 hydrogen and 1
        oxygen. Hence the name: "carbo" for carbon, and "hydrate" for water (H2O).
      </p>

      <p>
        While most of the concepts around carbohydrates are best left to organic chemistry, you
        should have some mental picture of sucrose since you are working with it in lab. Open
        <b>Odyssey</b>, go to the <b>Labs</b> tab, find the <i>Consumer Chemistry</i> and click on
        <b>W12 Soft Drinks</b>. Follow the instructions on the screen and click on all the available
        buttons.
      </p>

      <p>
        What two molecules can be combined together to form sucrose?
        <v-select
          :key="optionsA"
          v-model="inputs.inputA"
          class="d-sm-inline-block ml-3 mr-3"
          :items="optionsA"
          item-text="text"
          item-value="value"
          label="Part a)"
          style="width: 150px"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        and
        <v-select
          :key="optionsB"
          v-model="inputs.inputB"
          class="d-sm-inline-block ml-3 mr-3"
          :items="optionsB"
          item-text="text"
          item-value="value"
          label="Part b)"
          style="width: 150px"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p>
        Click on one of the atoms of sucrose so that it has a yellow gray appearance. Ctrl (or
        right) click to bring up the menu pictured below:
      </p>

      <p class="mb-5">
        <v-img
          src="/img/assignments/ChemUCI_1LC_Q1S_Q12_image.png"
          style="max-width: 329px"
          alt="image of the menu that appears when you right-click the sucrose atom"
        />
      </p>

      <p>
        Choose <b>Set Clipping Center</b>. Go to the Style menu at the top of the screen and choose
        <b>Hydrogen Bonds</b>. Push the play button at the bottom of the window beneath the
        molecules.
      </p>

      <p>
        Why does sucrose dissolve so easily in water?
        <v-select
          :key="optionsC"
          v-model="inputs.inputC"
          class="d-sm-inline-block ml-3 mr-3"
          :items="optionsC"
          item-text="text"
          item-value="value"
          label="Part c)"
          style="width: 350px"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';

export default {
  name: 'ChemUCI_1LC_Q1S_Q12',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        inputA: null,
        inputB: null,
        inputC: null,
      },
      optionsA: [
        {
          text: 'Corn syrup',
          value: 'cornSyrup',
        },
        {
          text: 'Water',
          value: 'water',
        },
        {
          text: 'Sugar',
          value: 'sugar',
        },
        {
          text: 'Glucose',
          value: 'glucose',
        },
      ],
      optionsB: [
        {
          text: 'Water',
          value: 'water',
        },
        {
          text: 'Fructose',
          value: 'fructose',
        },
        {
          text: 'Sucrose',
          value: 'sucrose',
        },
        {
          text: 'Carbohydrate',
          value: 'carbohydrate',
        },
      ],
      optionsC: [
        {
          text: 'Sucrose has a high molecular mass',
          value: 'a',
        },
        {
          text: 'Sucrose has a high carbon content',
          value: 'b',
        },
        {
          text: 'Sucrose has many $\\text{O-H}$ bonds',
          value: 'c',
        },
        {
          text: 'Water is able to create many dispersion force interactions',
          value: 'd',
        },
      ],
    };
  },
};
</script>
