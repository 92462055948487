var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',[_c('p',[_vm._v(" Sucrose is a carbohydrate; a molecule made up of carbon, hydrogen, and oxygen atoms. The approximate elemental make-up of most carbohydrates is 1 carbon for every 2 hydrogen and 1 oxygen. Hence the name: \"carbo\" for carbon, and \"hydrate\" for water (H2O). ")]),_c('p',[_vm._v(" While most of the concepts around carbohydrates are best left to organic chemistry, you should have some mental picture of sucrose since you are working with it in lab. Open "),_c('b',[_vm._v("Odyssey")]),_vm._v(", go to the "),_c('b',[_vm._v("Labs")]),_vm._v(" tab, find the "),_c('i',[_vm._v("Consumer Chemistry")]),_vm._v(" and click on "),_c('b',[_vm._v("W12 Soft Drinks")]),_vm._v(". Follow the instructions on the screen and click on all the available buttons. ")]),_c('p',[_vm._v(" What two molecules can be combined together to form sucrose? "),_c('v-select',{key:_vm.optionsA,staticClass:"d-sm-inline-block ml-3 mr-3",staticStyle:{"width":"150px"},attrs:{"items":_vm.optionsA,"item-text":"text","item-value":"value","label":"Part a)"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.inputA),callback:function ($$v) {_vm.$set(_vm.inputs, "inputA", $$v)},expression:"inputs.inputA"}}),_vm._v(" and "),_c('v-select',{key:_vm.optionsB,staticClass:"d-sm-inline-block ml-3 mr-3",staticStyle:{"width":"150px"},attrs:{"items":_vm.optionsB,"item-text":"text","item-value":"value","label":"Part b)"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.inputB),callback:function ($$v) {_vm.$set(_vm.inputs, "inputB", $$v)},expression:"inputs.inputB"}})],1),_c('p',[_vm._v(" Click on one of the atoms of sucrose so that it has a yellow gray appearance. Ctrl (or right) click to bring up the menu pictured below: ")]),_c('p',{staticClass:"mb-5"},[_c('v-img',{staticStyle:{"max-width":"329px"},attrs:{"src":"/img/assignments/ChemUCI_1LC_Q1S_Q12_image.png","alt":"image of the menu that appears when you right-click the sucrose atom"}})],1),_c('p',[_vm._v(" Choose "),_c('b',[_vm._v("Set Clipping Center")]),_vm._v(". Go to the Style menu at the top of the screen and choose "),_c('b',[_vm._v("Hydrogen Bonds")]),_vm._v(". Push the play button at the bottom of the window beneath the molecules. ")]),_c('p',[_vm._v(" Why does sucrose dissolve so easily in water? "),_c('v-select',{key:_vm.optionsC,staticClass:"d-sm-inline-block ml-3 mr-3",staticStyle:{"width":"350px"},attrs:{"items":_vm.optionsC,"item-text":"text","item-value":"value","label":"Part c)"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.inputC),callback:function ($$v) {_vm.$set(_vm.inputs, "inputC", $$v)},expression:"inputs.inputC"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }